import React from "react";



const ContactsMenu: React.FC = () => {

   return <>

         {/*<p className="title_1">Мои контакты</p>*/}
         <div className="contactsBlock">
            <p><span>skype:</span> <a href="skype:rus_webcustom">rus_webcustom</a></p>
            <p><span>e-mail:</span> <a href="mailto:webcustom1@gmail.com">webcustom1@gmail.com</a></p>
         </div>
   </>
}






export default ContactsMenu;