import React, {useEffect, useState} from "react";

interface TypePropsLetter {
   time: number
   letter: string
   key: number
}
interface TypePropsLogo {
   addClass?: string
   animate?: boolean
   animateStart?: boolean
}


const Letter: React.FC<TypePropsLetter> = (props) => {

   const [showElem, setShowElem] = useState<boolean>(false);

   useEffect(() => {
      let isMounted = true //для избежания ошибки утечки памети в useEffect
      setTimeout(function(){
         if(isMounted) {
            setShowElem(true)
         }
      },props.time)
      return () => {
         isMounted = false
      }
   },[])
   return <>
      <span className={showElem ? ' _show' : ''}>{props.letter}</span>
   </>
}


const Logo_1: React.FC<TypePropsLogo> = (props) => {
   // console.log(props.animateStart)
   const [gearAnim, setGearAnim] = useState<boolean>(false);

   useEffect(() => {
      if(props.animateStart) {
         setTimeout(function () {
            setGearAnim(true)
         }, 200)
      }
   },[props.animateStart])


   let textLogo = 'Web_Custom'
   let letterAnim: JSX.Element[] = []
   if(props.animateStart){
      let arrLogo = textLogo.split('')
      let time = 100

      letterAnim = arrLogo.map((elem, index) => {
         time += 100
         return <Letter key={index} time={time} letter={elem}/>
      })
   }


   let condition_1 = props.addClass && props.addClass
   let condition_2 = props.animate ? 'logoCode_1 _animateActive '+ condition_1 : 'logoCode_1 '+ condition_1


   return <>

          <div className={condition_2}>
             <div className={gearAnim ? 'gear _show' : 'gear'}>
                <svg className='gearImg' viewBox="0 0 205.42 231.31">
                   <path
                       d="M99.06,230.78s-2.72-7.6-4.46-12.89-3.72-10.29-4.38-11.13-4.14-2.49-7.72-3.65a62.45,62.45,0,0,1-10.4-4.48l-3.9-2.36-12.52,5.92-12.53,5.92-9.83-9.78c-5.4-5.39-9.82-10.36-9.82-11.05s2.5-6.51,5.55-12.93l5.55-11.67-3.52-7.22a84.65,84.65,0,0,1-4.7-11.61l-1.17-4.39L12.85,135,.5,130.52v-30L12.85,96l12.36-4.47,1.17-4.39a84.65,84.65,0,0,1,4.7-11.61l3.52-7.22L29.05,56.65C26,50.23,23.5,44.41,23.5,43.72s4.42-5.66,9.82-11l9.83-9.78,12.53,5.92L68.2,34.73l3.9-2.36a62.4,62.4,0,0,1,10.4-4.49c3.58-1.17,6.9-2.49,7.38-2.94s2.74-6.12,5-12.62L99,.5h30l4.12,11.82c2.26,6.5,4.51,12.18,5,12.62s3.81,1.77,7.38,2.94a62,62,0,0,1,10.4,4.49l3.9,2.36,12.52-5.92,12.53-5.92,9.83,9.78c5.4,5.39,9.82,10.36,9.82,11S202,50.23,199,56.65L193.4,68.32,197,75.64A64.31,64.31,0,0,1,201.15,86l.6,3h-14L183,79.43c-3.67-7.39-6.59-11.51-12.82-18.1-9-9.46-15.41-14-26.76-18.87C133.29,38.13,126,36.71,114,36.71s-19.29,1.42-29.41,5.75c-11.35,4.86-17.8,9.41-26.76,18.87-6.2,6.55-9.14,10.7-12.68,17.85C38.37,92.91,37,98.91,37,115.5s1.34,22.59,8.12,36.32c5.3,10.72,16.88,23.9,26.29,29.92,7.27,4.65,19.29,9.75,26.66,11.3,7.58,1.61,24.22,1.61,31.8,0,10.75-2.27,25.16-9.29,33.38-16.26l3.29-2.78H198.3l2,3.36a50.74,50.74,0,0,1,3.31,7l1.32,3.66-10,10-10,10-12.54-5.92-12.53-5.93-3.9,2.36a62,62,0,0,1-10.4,4.49c-3.57,1.17-6.9,2.49-7.38,2.94s-2.74,6.12-5,12.62L129,230.81l-14.7,0c-13.24,0-15.24,0-15.24,0Z"/>
                </svg>
                <svg className='shadowGear' viewBox="0 0 205.42 231.31">
                   <path
                       d="M99.06,230.78s-2.72-7.6-4.46-12.89-3.72-10.29-4.38-11.13-4.14-2.49-7.72-3.65a62.45,62.45,0,0,1-10.4-4.48l-3.9-2.36-12.52,5.92-12.53,5.92-9.83-9.78c-5.4-5.39-9.82-10.36-9.82-11.05s2.5-6.51,5.55-12.93l5.55-11.67-3.52-7.22a84.65,84.65,0,0,1-4.7-11.61l-1.17-4.39L12.85,135,.5,130.52v-30L12.85,96l12.36-4.47,1.17-4.39a84.65,84.65,0,0,1,4.7-11.61l3.52-7.22L29.05,56.65C26,50.23,23.5,44.41,23.5,43.72s4.42-5.66,9.82-11l9.83-9.78,12.53,5.92L68.2,34.73l3.9-2.36a62.4,62.4,0,0,1,10.4-4.49c3.58-1.17,6.9-2.49,7.38-2.94s2.74-6.12,5-12.62L99,.5h30l4.12,11.82c2.26,6.5,4.51,12.18,5,12.62s3.81,1.77,7.38,2.94a62,62,0,0,1,10.4,4.49l3.9,2.36,12.52-5.92,12.53-5.92,9.83,9.78c5.4,5.39,9.82,10.36,9.82,11S202,50.23,199,56.65L193.4,68.32,197,75.64A64.31,64.31,0,0,1,201.15,86l.6,3h-14L183,79.43c-3.67-7.39-6.59-11.51-12.82-18.1-9-9.46-15.41-14-26.76-18.87C133.29,38.13,126,36.71,114,36.71s-19.29,1.42-29.41,5.75c-11.35,4.86-17.8,9.41-26.76,18.87-6.2,6.55-9.14,10.7-12.68,17.85C38.37,92.91,37,98.91,37,115.5s1.34,22.59,8.12,36.32c5.3,10.72,16.88,23.9,26.29,29.92,7.27,4.65,19.29,9.75,26.66,11.3,7.58,1.61,24.22,1.61,31.8,0,10.75-2.27,25.16-9.29,33.38-16.26l3.29-2.78H198.3l2,3.36a50.74,50.74,0,0,1,3.31,7l1.32,3.66-10,10-10,10-12.54-5.92-12.53-5.93-3.9,2.36a62,62,0,0,1-10.4,4.49c-3.57,1.17-6.9,2.49-7.38,2.94s-2.74,6.12-5,12.62L129,230.81l-14.7,0c-13.24,0-15.24,0-15.24,0Z"/>
                </svg>
             </div>
             <p className={'textLogo'}>
                { props.animate ? letterAnim : textLogo}
             </p>
          </div>


      {/*{htmlEl}*/}

   </>
}

export default Logo_1